import { useMemo, useState } from 'react'
import type { OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Select } from '@sevenrooms/core/ui-kit/core'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { Grid, BaseSection, BorderedBox, VStack } from '@sevenrooms/core/ui-kit/layout'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { useGetEmailCampaignReservationsQuery } from '../../hooks'
import { ReservationsTable } from './ReservationsTable'

const DAYS_OPTIONS = [1, 7, 14, 30]

export function CampaignPerformanceReservations({ emailCampaign }: { emailCampaign: OneTimeEmailCampaignType }) {
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const { formatMessage } = useLocales()
  const [selectedDay, setSelectedDay] = useState('30')

  const options = useMemo(
    () => [
      { id: 'directClick', label: formatMessage(campaignCenterMessages.attributionWindowDirectClick) },
      ...DAYS_OPTIONS.map(day => ({ id: `${day}`, label: formatMessage(campaignCenterMessages.attributionWindowDays, { count: day }) })),
    ],
    [formatMessage]
  )

  const isPOSActivated = venueSettings?.pos_activated
  const averageSpendPerCover = venueSettings?.average_spend_per_cover

  const { startDate, endDate } = useMemo(() => {
    const endDate = new Date()
    const startDate = new Date(endDate)
    startDate.setDate(endDate.getDate() - parseInt(selectedDay))
    return { startDate, endDate }
  }, [selectedDay])

  const { data: reservationData } = useGetEmailCampaignReservationsQuery({
    id: emailCampaign.id,
    venueId: venue.id,
    startDate: startDate.toISOString().slice(0, 10),
    endDate: endDate.toISOString().slice(0, 10),
  })

  const { totalRevenue, avgLeadTime } = useMemo(() => {
    let totalRevenue = 0
    let totalLeadTime = 0

    if (reservationData?.data) {
      for (const res of reservationData.data.reservations) {
        totalRevenue += !isPOSActivated && averageSpendPerCover ? res.covers * averageSpendPerCover : res.spend

        const createdDate = new Date(res.created)
        const date = new Date(res.date)
        totalLeadTime += Math.max(date.getTime() - createdDate.getTime(), 0) // Don't allow negatives, which happen on same-day bookings
      }
    }

    // Convert from millis to days
    totalLeadTime = totalLeadTime / 1000 / 60 / 60 / 24
    const numReservations = reservationData?.data.reservations ? reservationData.data.reservations.length : 0
    const avgLeadTime = Math.round(totalLeadTime / numReservations)

    return { totalRevenue, avgLeadTime }
  }, [reservationData, isPOSActivated, averageSpendPerCover])

  return (
    <VStack spacing="lm">
      <Label primary={formatMessage(campaignCenterMessages.attributionWindow)} width={440}>
        <Select data-test="attribution-window-select" options={options} value={selectedDay} searchable={false} onChange={setSelectedDay} />
      </Label>
      <Grid gridTemplateColumns={`1fr ${isPOSActivated ? 2 : 1}fr`} gap="lm">
        <BaseSection
          title={`${venue.currencySymbol} ${totalRevenue}`}
          subCaption={formatMessage(campaignCenterMessages.campaignPerformanceRevenueGenerated)}
          padding="m"
          collapsed
        />
        <Grid gridTemplateColumns={`1fr ${isPOSActivated ? '1fr' : ''}`} gap="lm">
          {isPOSActivated && (
            <BaseSection
              title={formatMessage(commonMessages.hours, { count: 2 })}
              subCaption={formatMessage(campaignCenterMessages.campaignPerformanceTimeBetween)}
              padding="m"
              collapsed
            />
          )}
          <BaseSection
            title={formatMessage(commonMessages.daysInAdvance, { count: Number.isNaN(avgLeadTime) ? 0 : avgLeadTime })}
            subCaption={formatMessage(campaignCenterMessages.campaignPerformanceBookingLeadTime)}
            padding="m"
            collapsed
          />
        </Grid>
      </Grid>
      <Grid gridTemplateColumns="1fr 2fr" gap="lm">
        <BaseSection
          maxWidth="none"
          title={formatMessage(campaignCenterMessages.campaignPerformanceTop3CohortsTitle)}
          description={formatMessage(campaignCenterMessages.campaignPerformanceTop3CohortsDescription)}
        >
          <BorderedBox backgroundColor="margin" height="361px" m="lm" />
        </BaseSection>
        <BaseSection
          maxWidth="none"
          title={formatMessage(campaignCenterMessages.campaignPerformanceBookingsTitle)}
          description={formatMessage(campaignCenterMessages.campaignPerformanceBookingsDescription)}
        >
          <BorderedBox backgroundColor="margin" height="361px" m="lm" />
        </BaseSection>
      </Grid>
      <ReservationsTable
        emailCampaign={emailCampaign}
        isPOSActivated={isPOSActivated}
        startDate={startDate}
        endDate={endDate}
        reservationData={reservationData?.data}
      />
    </VStack>
  )
}
