import _ from 'lodash'
import { useMemo } from 'react'
import type { OneTimeEmailCampaignType } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { Skeleton } from '@sevenrooms/react-components'
import { useGetEmailCampaignLinkPerformanceQuery } from '../../../hooks'

export function TopClicksPerformance({ emailCampaign }: { emailCampaign: OneTimeEmailCampaignType }) {
  const { venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const { data: emailCampaignLinkPerformanceData, isFetching } = useGetEmailCampaignLinkPerformanceQuery({
    id: emailCampaign.id,
    venueId,
  })
  const topLinkPerformance = useMemo(
    () => _.orderBy(emailCampaignLinkPerformanceData?.data?.campaignLinks ?? [], ['uniqueClicks'], ['desc']).slice(0, 5),
    [emailCampaignLinkPerformanceData]
  )

  return (
    <VStack p="lm">
      <HStack display="flex" spacing="m">
        <HStack flex="1">
          <Text fontWeight={700} width="100%">
            {formatMessage(campaignCenterMessages.link)}
          </Text>
        </HStack>
        <Text fontWeight={700} width="90px" textAlign="end">
          {formatMessage(campaignCenterMessages.campaignPerformanceUniqueClicks)}
        </Text>
        <Text fontWeight={700} width="90px" textAlign="end">
          {formatMessage(campaignCenterMessages.campaignPerformanceTotalClicks)}
        </Text>
      </HStack>
      {isFetching ? (
        <Skeleton variant="rounded" sx={{ height: 74, width: '100%' }} />
      ) : (
        topLinkPerformance.map(row => (
          <HStack key={`${row.url}##${row.orderOfAppearance}`} borderTopColor="margin" pt="m" mt="m" spacing="m">
            <VStack flex={1} spacing="s" overflow="hidden">
              <Text textOverflow="ellipsis">{row.url}</Text>
              <Text color="secondaryFont" textOverflow="ellipsis">
                {row.description}
              </Text>
            </VStack>
            <Text width="90px" textAlign="end">
              {row.uniqueClicks}
            </Text>
            <Text width="90px" textAlign="end">
              {row.totalClicks}
            </Text>
          </HStack>
        ))
      )}
    </VStack>
  )
}
